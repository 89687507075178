import {get, post, put,Delete} from "@/api/axios";

export const list = params => get('/web/shop/spec/list', params);

// /web/shop/spec/supplier/list get
export const listSupplier = params => get('/web/shop/spec/supplier/list', params);

// /web/shop/spec/add post
export const add = params => post('/web/shop/spec/add', params);

// /web/shop/spec/delete/{id} Delete
export const deleteSpec = params => Delete('/web/shop/spec/delete/' + params.id, '');

// /web/shop/spec/view/{id} get
export const view = params => get('/web/shop/spec/view/' + params.id, '');

// /web/shop/spec/update put
export const update = params => put('/web/shop/spec/update', params);